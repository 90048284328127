<template>
  <div>
    <div class="d-flex justify-start align-center mb-8">
      <div>
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text mr-2"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <div v-if="isLoadingInstance">
          <v-progress-circular
            color="primary"
            :size="30"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <span class="d-block text-caption text-capitalize black--text">{{ typeInstance }}</span>
          <span
            class="d-block text-subtitle-1 font-weight-bold black--text"
            style="line-height: 1.5rem !important"
          >
            {{ instansi.name }}
          </span>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <v-card class="pa-6 mx-0">
        <v-row class="justify-space-between align-center">
          <v-col
            md="6"
            xl="6"
            cols="6"
            sm="12"
          >
            <h2>Koordinat Spod</h2>
            <small style="white-space: normal;">Halaman untuk menambahkan mengatur dan memantau Titik Spod sesuai Koordinat</small>
          </v-col>
        </v-row>
        <v-row class="justify-space-between align-center">
          <v-col
            md="2"
            xl="2"
            cols="2"
            sm="12"
          >
            <v-row class="align-center">
              <v-col md="5">
                <v-avatar
                  rounded="lg"
                  color="#FFB400"
                  size="60"
                >
                  <v-container class="d-flex align-center">
                    <v-icon color="white">
                      {{ icons.mdiMapMarker }}
                    </v-icon>
                  </v-container>
                </v-avatar>
              </v-col>
              <v-col md="7">
                <span class="d-block">Jumlah</span>
                <h2>{{ totalItems }}</h2>
                <!-- <v-progress-circular
                  v-if="loadingLib"
                  indeterminate
                  :color="instance.color"
                ></v-progress-circular> -->
                <div
                  class="d-flex align-center flex wrap"
                >
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            md="3"
            xl="3"
            cols="3"
            sm="12"
          >
            <!-- <v-btn
              outlined
              color="primary"
              x-large
              width="100%"
              @click.prevent=""
            >
              <v-icon>{{ icons.mdiPoll }}</v-icon> <span class="ml-2">Statistik Semua Spod</span>
            </v-btn> -->
          </v-col>
          <v-col
            md="3"
            xl="3"
            cols="3"
            sm="12"
          ></v-col>
          <v-col
            md="4"
            xl="4"
            cols="4"
            sm="12"
          >
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
              @change="searchHandler"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <div>
      <v-row
        v-if="spods.length || !isLoadingData"
        class="align-center"
      >
        <!-- <v-col

          md="4"
          cols="12"
        >
          <a
            class="text-decoration-none"
            @click.prevent="showFormAdd()"
          >
            <v-card
              height="330"
              color="#F5F5F5"
              style="border: 1px dashed #c4c4c4;"
              class="d-flex align-center justify-center"
            >
              <div class="text-center">
                <v-icon size="40">
                  {{ icons.mdiPlus }}
                </v-icon>
                <p>Tambah Slider</p>
              </div>
            </v-card>
          </a>
        </v-col> -->

        <v-col
          v-for="(spodItem,index) in spods"
          :key="index"
          md="4"
          cols="12"
        >
          <v-card
            min-height="307"
          >
            <v-card
              class="overflow-hidden"
              style="z-index: 0"
            >
              <v-card-title
                class="pa-2 position-absolute"
                style="right: 0; z-index: 1;"
              >
                <v-spacer></v-spacer>
              </v-card-title>

              <div
                style="z-index: 0;"
              >
                <v-img
                  v-if="spodItem.status === '1'"
                  :src="icons.imageSpod"
                  height="200"
                ></v-img>
                <v-img
                  v-else
                  :src="icons.imageSpod"
                  height="200"
                  class="image-noaktif"
                ></v-img>
              </div>
            </v-card>

            <v-card-text class="py-4">
              <div class="d-flex">
                <v-chip
                  v-if="spodItem.status === '1'"
                  class="v-chip-light-bg success--text font-weight-semibold mr-2"
                  dark
                >
                  {{ statusArray[spodItem.status] }}
                </v-chip>
                <v-chip
                  v-else
                  class="v-chip-light-bg error--text font-weight-semibold mr-2"
                  dark
                >
                  {{ statusArray[spodItem.status] }}
                </v-chip>
                <p class="font-weight-light py-1">
                  Sampai {{ formatDate(spodItem.end_date) }}
                </p>
              </div>
              <div class="d-flex mb-6">
                <v-chip
                  x-large
                  label
                  class="v-chip-light-bg primary--text"
                  style="width: 100%;"
                >
                  <v-row>
                    <v-col cols="2">
                      <v-icon size="50">
                        {{ icons.mdiCodeArray }}
                      </v-icon>
                    </v-col>
                    <v-col
                      cols="4"
                      class="pt-7"
                    >
                      <p class="black--text">
                        Spod ID
                      </p>
                    </v-col>
                    <v-col
                      cols="6"
                      class="pt-7"
                    >
                      <h3 class="font-weight-bold primary--text">
                        {{ spodItem.spod_id }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-chip>
              </div>
              <div class="mb-2 d-flex align-center justify-space-between">
                <h2 v-if="spodItem.spod_name === null">
                  Nama Spod
                </h2>
                <h2
                  v-else
                  class="text-left"
                >
                  {{ spodItem.spod_name }}
                </h2>
              </div>
              <div class="d-flex mb-2">
                <v-icon
                  size="25"
                  class="mr-2"
                >
                  {{ icons.mdiMapMarker }}
                </v-icon>
                <span
                  v-if="spodItem.latitude !== null && spodItem.longitude !== null"
                  class="font-weight-light text-truncate mb-2"
                >{{ spodItem.latitude }}, {{ spodItem.longitude }}</span>
                <span
                  v-else
                  class="font-weight-light text-truncate mb-2"
                >Latitude, Longitude</span>
              </div>
              <div class="d-flex mb-4">
                <v-icon
                  size="25"
                  class="mr-2"
                >
                  {{ icons.mdiAccountGroup }}
                </v-icon>
                <span class="font-weight-light text-truncate mb-2">{{ spodItem.statistics.total_history }} Pengunjung </span>
              </div>
              <hr
                style="border-top: 1px solid #3A35411F;"
                class="mb-4"
              >
              <div class="d-flex mb-4 px-5">
                <v-row class="text-center">
                  <v-col
                    cols="4"
                  >
                    <a @click.prevent="showFormEdit(spodItem.uuid)">
                      <div>
                        <v-icon size="40">
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                      </div>
                      <h3 class="text-no-wrap font-weight-light">
                        EDIT
                      </h3>
                    </a>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      <a @click.prevent="openQRCode(spodItem.uuid)">
                        <div>
                          <v-icon size="40">
                            {{ icons.mdiQrcode }}
                          </v-icon>
                        </div>
                        <h3 class="text-no-wrap font-weight-light">
                          CETAK QR
                        </h3>
                      </a>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      <a @click.prevent="instanceSpod(spodItem.spod_id)">
                        <div>
                          <v-icon
                            size="40"
                            color="#AC268F"
                          >
                            {{ icons.mdiPoll }}
                          </v-icon>
                        </div>
                        <h3 class="text-no-wrap font-weight-light primary--text">
                          STATISTIK
                        </h3>
                      </a>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          md="1"
          cols="12"
        >
          <infinite-loading
            slot="append"
            spinner="spiral"
            :identifier="infiniteId"
            @infinite="infiniteHandler"
            @distance="1"
          >
            <div slot="no-more">
            </div>
            <div slot="no-results">
            </div>
          </infinite-loading>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col
          v-for="i in 3"
          :key="i"
          md="4"
        >
          <v-skeleton-loader
            type="image"
            :loading="true"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="openQRCodeDialog"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title
          class="headline justify-center"
        ></v-card-title>

        <v-icon
          style="position:absolute;top:20px;right:20px"
          @click="handleCloseQRCodeDialog"
        >
          {{ icons.mdiClose }}
        </v-icon>

        <div
          v-if="!isLoadingQr"
          class="text-center pa-4"
        >
          <h3>
            {{ qrCode.name }}
          </h3>
          <a
            :href="qrCode.link"
            download
            target="_blank"
          >
            <v-img
              :src="qrCode.link"
              :lazy-src="qrCode.link"
              alt="qrcode"
            ></v-img>
          </a>
        </div>

        <div
          v-else
          class="text-center py-5 px- 5"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            :size="50"
            :width="5"
          ></v-progress-circular>
        </div>

        <div
          v-if="!qrCode.link && !isLoadingQr"
          class="text-center pa-4"
        >
          <v-img src="@/assets/images/logos/qr-not-found.svg"></v-img>
        </div>
      </v-card>
    </v-dialog>

    <ModalDialog
      :visible="modalDialog"
      :width="800"
      :is-loading-button="isLoadingButton"
      :dialog="dialog"
      :header="'Edit Spod'"
      :text-button="'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row class="py-2">
          <v-col
            v-if="dialog !== 'add'"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="spod.spod_name"
                label="Spod Name"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            xl="12"
            lg="12"
            md="12"
            sm="12"
            cols="12"
            class="py-0 mb-4"
          >
            <!-- <div>
              <v-autocomplete
                v-model="searchMap"
                :items="locations"
                label="Search Location"
                @change="updateMap"
              ></v-autocomplete>
            </div> -->
            <!-- <vuetify-google-autocomplete
              id="map"
              ref="address"
              classname="form-control"
              placeholder="Please type your address"
              country="sg"
              @placechanged="getAddressData"
            >
            </vuetify-google-autocomplete> -->
            <div>
              <GmapMap
                ref="googleMap"
                :center="center"
                :zoom="7"
                class="map"
                @click="mapClick"
              >
                <GmapMarker :position="{ lat: lat, lng: long }" />
              </GmapMap>
              <!-- <GmapMap
                :center="mapCenter"
                :zoom="mapZoom"
                style="width: 100%; height: 400px"
                @click="addMarker"
              >
                <GmapMarker
                  v-for="(marker, index) in markers"
                  :key="index"
                  :position="marker.position"
                  :clickable="true"
                  @click="markerClicked(marker)"
                />
              </GmapMap> -->
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="6"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="spod.latitude"
                label="Spod Latitude"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="6"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="spod.longitude"
                label="Spod Longitude"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>

    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Spod"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import imageSpod from '@/assets/images/spod/spod.svg'
import dateTimeFormat from '@/utils/date/dateTimeFormat'
// eslint-disable-next-line object-curly-newline
import router from '@/router'
import {
  mdiAccountGroup,
  mdiArrowDownThin,
  mdiArrowLeft,
  mdiArrowUpThin,
  mdiClose,
  mdiCodeArray,
  mdiCrop,
  mdiDotsVertical,
  mdiImageMultipleOutline,
  mdiInformation,
  mdiMagnify,
  mdiMapMarker,
  mdiPencilOutline,
  mdiPlus,
  mdiPoll,
  mdiQrcode,
  mdiRefresh,
  mdiTownHall,
} from '@mdi/js'
import 'cropperjs/dist/cropper.css'
import Dayjs from 'dayjs'
import InfiniteLoading from 'vue-infinite-loading'
import ConfirmDialog from '../../components/ConfirmDialog.vue'
// import MarkMapBounds from '../components/MarkMapBounds'
// import * as VueGoogleMaps from 'vue2-google-maps'
import ModalDialog from '../../components/ModalDialog.vue'

export default {
  name: 'Spod',
  components: {
    // VueCropper,
    ModalDialog,
    ConfirmDialog,
    // FileInput,
    InfiniteLoading,
    // Dayjs,
    // MarkMapBounds,
  },
  data() {
    return {
      field: {
        value: { lat: 41.1621268, long: -8.606188100000054 },
      },
      icons: {
        imageSpod,
        mdiArrowLeft,
        mdiPlus,
        mdiImageMultipleOutline,
        mdiDotsVertical,
        mdiInformation,
        mdiRefresh,
        mdiCrop,
        mdiArrowUpThin,
        mdiArrowDownThin,
        mdiTownHall,
        mdiMapMarker,
        mdiCodeArray,
        mdiAccountGroup,
        mdiMagnify,
        mdiPencilOutline,
        mdiQrcode,
        mdiPoll,
        mdiClose,
      },
      instances: [
        {
          icon: mdiTownHall,
          color: '#AC268F',
        },
      ],
      typeArray: {
        company: 'Perusahaan',
        school: 'Sekolah',
        library: 'Perpustakaan',
      },
      statusArray: {
        1: 'AKTIF',
        0: 'TIDAK AKTIF',
      },
      instansi: '',
      typeInstance: '',
      imgSrc: '',
      infiniteId: +new Date(),
      error: false,
      errorURL: false,
      imageCropped: false,
      end_date: '',
      start_date: '',
      isLoadingCard: true,
      isLoadingButton: false,
      isLoadingInstance: false,
      loadingImage: false,
      openQRCodeDialog: false,
      modalDialog: false,
      isLoadingQr: false,
      qrCode: {
        name: null,
        link: null,
      },
      dialog: '',
      search: '',
      filterQuery: {
        type: '',
      },
      confirmDialog: false,
      page: 1,
      isLoadingData: true,
      totalItems: 0,
      totalPagesLib: 100,
      showCropper: false,
      pageLib: 1,
      spods: [],
      spod: {
        uuid: null,
        company_uuid: null,
        spod_id: '',
        spod_name: '',
        latitude: '',
        longitude: '',
        start_date: '',
        end_date: '',
        status: 0,
        total_spod: 0,
      },
      statusItem: [
        { label: 'Aktif', value: '1' },
        { label: 'Tidak Aktif', value: '0' },
      ],
      url: '',
      isValid: false,
      invalidMessage: '',
      messageOrder: '',
      serviceSpod: 'spod',
      formValid: false,
      moveInterval: null,
      endDate: '',
      instanceUuid: JSON.parse(localStorage.getItem('user')).user.company_uuid,
      // instanceUuid: JSON.parse(localStorage.getItem('user')).user.company_uuid,
      center: { lat: 3.6422715, lng: 98.5046837 },
      lat: 0,
      long: 0,
      latLng: {},
      coords: {},
      locationDetails: {},
      address: '',
      // searchMap: '',
      // locations: [],
      // mapCenter: { lat: 3.6422715, lng: 98.5046837 },
      // mapZoom: 7,
      // markers: [],
    }
  },
  watch: {
    page: {
      handler() {
        this.listSpod({ company_uuid: this.instanceUuid })
      },
    },
  },
  created() {
    this.listSpod()
  },
  mounted() {
    this.getInstance(this.instanceUuid)
    // this.$refs.address.focus()
    // // Initialize the Google Maps autocomplete service
    // const autocompleteService = new VueGoogleMaps.gmapApi.maps.places.AutocompleteService()

    // // Listen for input changes in the search bar and update the autocomplete suggestions
    // this.$watch('search', () => {
    //   if (this.search.length > 0) {
    //     autocompleteService.getPlacePredictions(
    //       { input: this.search },
    //       (predictions, status) => {
    //         if (status === 'OK') {
    //           this.locations = predictions.map(prediction => prediction.description)
    //         } else {
    //           this.locations = []
    //         }
    //       },
    //     )
    //   } else {
    //     this.locations = []
    //   }
    // })
  },
  methods: {
    // setPlace(place) {
    //  this.coords.value.lat = place.geometry.location.lat()
    //  this.coords.value.lng = place.geometry.location.lng()
    //  // Update the location details
    //  this.locationDetails.value.address = place.formatted_address
    //  this.locationDetails.value.url = place.url
    // },
    mapClick({ latLng: { lat, lng } }) {
      const latLng = { lat: lat(), lng: lng() }
      this.lat = lat()
      this.long = lng()
      this.spod.latitude = lat()
      this.spod.longitude = lng()
      console.log(latLng)
    },
    // getAddressData(addressData, placeResultData, id) {
    //   this.address = addressData
    //   console.log(this.address)
    // },
    // updateMap(place) {
    //   // Handle the case when user selects a location from the autocomplete dropdown
    //   if (place && place.geometry) {
    //     this.mapCenter = {
    //       lat: place.geometry.location.lat(),
    //       lng: place.geometry.location.lng(),
    //     }
    //     this.mapZoom = 12
    //     this.addMarkerFromPlace(place)
    //   } else {
    //     // Handle other cases, e.g., when user enters a custom location
    //     this.geocodeLocation(this.searchMap)
    //   }
    // },
    // geocodeLocation(location) {
    //   // Geocode the location using Google Maps Geocoding API
    //   const geocoder = new VueGoogleMaps.gmapApi.maps.Geocoder()

    //   geocoder.geocode({ address: location }, (results, status) => {
    //     if (status === 'OK' && results.length > 0) {
    //       const geometry = results[0].geometry.location
    //       this.mapCenter = { lat: geometry.lat(), lng: geometry.lng() }
    //       this.mapZoom = 12
    //       this.addMarkerFromPlace(results[0])
    //     } else {
    //       // Handle geocoding errors or invalid locations
    //       console.error('Geocoding error or invalid location:', status)
    //     }
    //   })
    // },
    // addMarkerFromPlace(place) {
    //   // Add a marker based on the selected place
    //   const newMarker = {
    //     position: {
    //       lat: place.geometry.location.lat(),
    //       lng: place.geometry.location.lng(),
    //     },
    //   }
    //   this.markers = [newMarker]
    // },
    // addMarker(event) {
    //   // Add a marker when user clicks on the map
    //   const newMarker = { position: { lat: event.latLng.lat(), lng: event.latLng.lng() } }
    //   this.markers = [newMarker]
    // },
    // markerClicked(marker) {
    //   // Implement logic for marker click event
    //   console.log('Marker clicked:', marker)
    // },

    formatDate(dateString) {
      const date = Dayjs(dateString)
      // Then specify how you want your dates to be formatted

      return date.format('D MMMM YYYY')
    },

    async infiniteHandler($state) {
      this.pageLib += 1

      await this.$services.ApiServices.list(this.serviceSpod, {
        page: this.pageLib,
        company_uuid: this.instanceUuid,
        statistic: 1,
      }).then(({ data }) => {
        if (data.data.length) {
          this.spods = [...this.spods, ...data.data]
          this.isLoadingCard = false
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },

    async getInstance(uuid) {
      await this.$services.ApiServices.get('instance', uuid).then(
        ({ data }) => {
          this.instansi = data.data
          this.typeInstance = this.typeArray[data.data.company_types_uuid.type]
        },
      )
    },

    // eslint-disable-next-line camelcase
    async instanceSpod(spod_id) {
      // this.openQRCodeDialog = true
      router.push({ name: 'statistic-instance-spod-show', params: { spod_id } })
    },

    async openQRCode(uuid) {
      this.isLoadingQr = true
      this.openQRCodeDialog = true
      await this.$services.ApiServices.get(this.serviceSpod, uuid).then(
        ({ data }) => {
          this.qrCode.link = data.data.qr_code
          this.qrCode.name = data.data.spod_id
        },
        err => {
          console.log(err)
        },
      )
      this.isLoadingQr = false
    },

    async listSpod(params = {}) {
      this.isLoadingData = true

      await this.$services.ApiServices.list(this.serviceSpod, {
        ...params,
        company_uuid: this.instanceUuid,
        statistic: 1,
      }).then(
        ({ data }) => {
          this.spods = data.data
          // console.log(this.spods.status)
          this.totalItems = data.meta.total
        },
        err => {
          console.error(err)
          this.isLoadingData = false
        },
      )
      this.isLoadingData = false
    },

    async update() {
      this.isLoadingButton = true

      this.formData = new FormData()
      this.formData.append('spod_name', this.spod.spod_name)
      this.formData.append('latitude', this.spod.latitude)
      this.formData.append('longitude', this.spod.longitude)
      this.formData.append('company_uuid', this.instanceUuid)

      await this.$services.ApiServices.update(this.serviceSpod, this.formData, this.spod.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.refreshInfiniteLoading()
          this.isLoadingButton = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )

      this.isLoadingButton = false
      this.modalDialog = false
      this.resetForm()
      this.listSpod()
    },

    async destroy() {
      this.isLoadingButton = true

      await this.$services.ApiServices.destroy(this.serviceSpod, this.spod.uuid).then(({ data }) => {
        this.showSnackbar({
          text: 'Spod has successfully deleted !',
          color: 'error',
        })
        this.refreshInfiniteLoading()
      })

      this.isLoadingButton = false
      this.confirmDialog = false
      this.listSpod()
    },

    searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      this.listSpod(this.filterQuery)
      // console.log(this.filterQuery.search)
    },

    refreshInfiniteLoading() {
      if (this.spods.length > 3) {
        this.pageLib = 1
        this.infiniteId += 1
      }
    },

    confirmDestroy(uuid, title) {
      this.confirmDialog = true
      this.spod.spod_id = title
      this.spod.uuid = uuid
    },

    showFormAdd() {
      this.invalidMessage = ''
      this.errorURL = false
      this.resetForm()
      this.dialog = 'add'
      this.messageOrder = ''
      this.error = false
      this.isLoadingData = false
      this.loadingImage = false
      this.modalDialog = true
    },

    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      this.isLoadingData = true

      await this.$services.ApiServices.get(this.serviceSpod, uuid).then(
        ({ data }) => {
          this.spod = data.data
          this.lat = Number(this.spod.latitude)
          this.long = Number(this.spod.longitude)
        },
        err => {
          console.log(err)
        },
      )
      this.isLoadingData = false
      this.loadingImage = false
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    autofillStartDate() {
      this.spod.start_date = new Date().toISOString().substr(0, 10)
    },

    autofillEndDate() {
      this.spod.end_date = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
    },

    resetForm() {
      this.spod.uuid = null
      this.spod.company_uuid = null
      this.spod.spod_id = ''
      this.spod.spod_name = ''
      this.spod.latitude = ''
      this.spod.longitude = ''
      this.spod.start_date = ''
      this.spod.end_date = ''
      this.spod.status = 0
      this.spod.total_spod = 0
    },

    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },

    handleCloseQRCodeDialog() {
      this.openQRCodeDialog = false
    },

    dateFormat(date) {
      dateTimeFormat(date, '-', false, false)
    },
  },
}
</script>

<style>
.map {
  height: 400px;
  width: 100%;
}
.alert > .v-alert__wrapper > .v-alert__content {
  display: flex;
  align-items: center;
}
.text-delete {
  color: #e30000 !important;
}
.theme--light.v-skeleton-loader .v-skeleton-loader__image {
  height: 307px;
}
.loading-spiral {
  border: 3px solid #777 !important;
  border-right-color: transparent !important;
  -webkit-animation: loading-rotating-data-v-46b20d22 linear .85s infinite !important;
  animation: loading-rotating-data-v-46b20d22 linear .85s infinite !important;
}

.btn-reset,
.btn-crop {
  width: 155px;
}

.btn-tools {
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
  min-width: 0 !important;
  padding: 0 !important;
}
.image-noaktif{
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>
